// someReducer.ts
import { createSlice } from "@reduxjs/toolkit";

interface SomeState {
  isFormUpdated: boolean; // Add isFormDataUpdated to the statez
  isReloadClientList: boolean;
}

const initialState: SomeState = {
  isFormUpdated: false, // Initialize isFormDataUpdated as false
  isReloadClientList: false,
};

const formUpdateSlice = createSlice({
  name: "formUpdate",
  initialState,
  reducers: {
    setFormData: (state) => {
      state.isFormUpdated = true; // Set isFormDataUpdated to true when someData changes
    },
    resetFormData: (state) => {
      state.isFormUpdated = false; // Set isFormDataUpdated to false when resetting data
    },
    setReloadClientList: (state) => {
      state.isReloadClientList = true;
    },
    resetReloadClientList: (state) => {
      state.isReloadClientList = false;
    },
  },
});

export const {
  setFormData,
  resetFormData,
  setReloadClientList,
  resetReloadClientList,
} = formUpdateSlice.actions;

export default formUpdateSlice.reducer;
