import * as React from "react";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_RowSelectionState,
} from "material-react-table";
import {
  Eye16Filled,
  MoreHorizontal24Filled,
  Edit16Filled,
  Dismiss24Regular,
  Add20Regular,
} from "@fluentui/react-icons";
import {
  Button,
  makeStyles,
  Tooltip,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
  Avatar,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Label,
  Dropdown,
  Option,
  useId,
  shorthands,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
} from "@fluentui/react-components";
import {
  camalize,
  formatedDate,
  formatedDatepickerDate,
  getAssetFundCodeSource,
  getYes_NoConversion,
} from "../../../utils";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    cursor: "pointer",
  },
  contentHeader: {
    marginTop: "0",
  },
  scopeArea: {
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
  },
});

type ComponentProps = {
  style?: React.CSSProperties;
  [key: string]: any;
};

const tableColumnList = {
  payment: [
    {
      accessorKey: "payment.start_date",
      header: "Start Date",
      Cell: ({ row }: any) => (
        <>
          {formatedDate(
            row.original.payment ? row.original.payment.start_date : null
          )}
        </>
      ),
    },
    {
      accessorKey: "payment.end_date",
      header: "End Date",
      Cell: ({ row }: any) => (
        <>
          {formatedDate(
            row.original.payment ? row.original.payment.end_date : null
          )}
        </>
      ),
    },
    {
      accessorKey: "payment.amount",
      header: "Amount",
    },
    {
      accessorKey: "payment.frequency",
      header: "Frequency",
    },

    {
      accessorKey: "payment.new_business_register",
      header: "New Business",
      Cell: ({ row }: any) => (
        <>{camalize(row.original.payment?.new_business_register)}</>
      ),
    },
    {
      accessorKey: "payment.reinvestment",
      header: "Reinvestment",
      Cell: ({ row }: any) => (
        <>{camalize(row.original.payment?.reinvestment)}</>
      ),
    },
    {
      accessorKey: "payment.note_text",
      header: "Admin Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.payment?.note_text} />
        </>
      ),
    },
    {
      accessorKey: "payment.payment_type",
      header: "Payment Type",
    },
  ],
  paymentCommission: [
    {
      accessorKey: "commission_type",
      header: "Commission Type",
    },
    {
      accessorKey: "payment_source",
      header: "Source of Payment",
    },
    {
      accessorKey: "frequency",
      header: "Frequency",
    },
    {
      accessorKey: "amount_to_company",
      header: "Amount to Company",
    },
    {
      accessorKey: "start_date",
      header: "Commission Start Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
    },
    {
      accessorKey: "end_date",
      header: "End Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,
    },
    {
      accessorKey: "payment_count",
      header: "Number of Payments",
    },
    {
      accessorKey: "nb_register",
      header: "Show AMT on NB Register",
      Cell: ({ row }: any) => (
        <>{getYes_NoConversion(row.original.nb_register)}</>
      ),
    },
    {
      accessorKey: "note_text",
      header: "Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
  ],
  withdrawals: [
    {
      accessorKey: "start_date",
      header: "Start Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
    },
    {
      accessorKey: "end_date",
      header: "End Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,
    },
    {
      accessorKey: "amount",
      header: "Amount",
    },
    {
      accessorKey: "frequency",
      header: "Frequency",
    },
    {
      accessorKey: "high_risk",
      header: "High Risk",
    },
    {
      accessorKey: "high_risk_note",
      header: "High Risk Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.high_risk_note} />
        </>
      ),
    },
    {
      accessorKey: "reinvestment",
      header: "Reinvestment?",
    },
    {
      accessorKey: "note_text",
      header: "Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
  ],
  valuations: [
    {
      accessorKey: "effective_date",
      header: "Effective Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.effective_date)}</>,
    },
    {
      accessorKey: "valuation_amount",
      header: "Valuation Amount",
    },
    {
      accessorKey: "note_text",
      header: "Admin Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
  ],
  actions: [
    {
      accessorKey: "business_date",
      header: "Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.business_date)}</>,
    },
    {
      accessorKey: "business_action",
      header: "Action",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.business_action} />
        </>
      ),
    },
    {
      accessorKey: "business_assigned",
      header: "Assigned",
    },
    {
      accessorKey: "business_status",
      header: "Status",
    },
  ],

  fund: [
    {
      accessorKey: "fund_code",
      header: "Fund Code",
    },
    {
      accessorKey: "fund_code_source",
      header: "Fund Source Code",
      Cell: ({ row }: any) => (
        <>{getAssetFundCodeSource(row.original.fund_code_source)}</>
      ),
    },
    {
      accessorKey: "fund_name",
      header: "Fund Name",
    },
    {
      accessorKey: "fund_units",
      header: "Units",
    },
    {
      accessorKey: "unit_price",
      header: "Price",
    },
    {
      accessorKey: "currency_code",
      header: "Currency",
    },
    {
      accessorKey: "price_date",
      header: "Effective Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.price_date)}</>,
    },

    {
      accessorKey: "fund_value",
      header: "Fund Value",
    },
    {
      accessorKey: "sector",
      header: "Sector",
    },
    {
      accessorKey: "total_expense_ratio",
      header: "Total Expence Ratio",
    },
    {
      accessorKey: "risk_rating",
      header: "Risk Rating",
    },
  ],
};

const PolicyDetails = (props: ComponentProps) => {
  const styles = useStyles();
  const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>(
    {}
  );
  const customColumns = React.useMemo<
    MRT_ColumnDef<{ [key: string]: any }>[]
  >(() => {
    if (props.policyActiveTabLevel2 !== "") {
      if (
        props.policyActiveTabLevel2 === "payment" &&
        props.policyActiveTabLevel2Subtab !== undefined &&
        props.policyActiveTabLevel2Subtab === "paymentCommission"
      ) {
        return (tableColumnList as { [key: string]: any })[
          props.policyActiveTabLevel2Subtab
        ];
      } else {
        return (tableColumnList as { [key: string]: any })[
          props.policyActiveTabLevel2
        ];
      }
    }
    return [];
  }, [props.policyActiveTabLevel2]);
  React.useEffect(() => {
    if (
      props.data.length > 0 &&
      props.handleDetailPageRowSelection !== undefined &&
      Object.keys(rowSelection)[0] !== undefined
    ) {
      let selectedRow = props.data[Object.keys(rowSelection)[0]];
      props.handleDetailPageRowSelection(Object.keys(rowSelection)[0]);
    }
  }, [rowSelection]);
  React.useEffect(() => {
    if (
      props.policySubActiveItem &&
      Object.keys(props.policySubActiveItem).length !== 0 &&
      props.data.length > 0
    ) {
      let index = 0;
      if (props.policyActiveTabLevel2 === "payment") {
        if (props.policyActiveTabLevel2Subtab === "paymentCommission") {
          index = props.data.findIndex(
            (x: any) =>
              x.commission_id === props.policySubActiveItem.commission_id
          );
        } else {
          if (props.policySubActiveItem.payment !== undefined) {
            index = props.data.findIndex(
              (x: any) =>
                x.payment.payment_id ===
                props.policySubActiveItem.payment.payment_id
            );
          }
        }
      } else if (props.policyActiveTabLevel2 === "withdrawals") {
        index = props.data.findIndex(
          (x: any) =>
            x.withdrawal_id === props.policySubActiveItem.withdrawal_id
        );
      } else if (props.policyActiveTabLevel2 === "actions") {
        index = props.data.findIndex(
          (x: any) => x.business_id === props.policySubActiveItem.business_id
        );
      } else if (props.policyActiveTabLevel2 === "valuations") {
        index = props.data.findIndex(
          (x: any) => x.valuation_id === props.policySubActiveItem.valuation_id
        );
      } else if (props.policyActiveTabLevel2 === "fund") {
        index = props.data.findIndex(
          (x: any) => x.fund_id === props.policySubActiveItem.fund_id
        );
      } else {
      }

      setRowSelection({ [index]: true });
      props?.setCurrentSubRecord(index + 1);
    }
  }, [props.policySubActiveItem]);
  // React.useEffect(() => {
  //   if (props.detailPageSelectedRowIndex !== undefined) {
  //     setRowSelection({ [props.detailPageSelectedRowIndex]: true });
  //   } else {
  //     setRowSelection({});
  //   }
  // }, [props.detailPageSelectedRowIndex]);
  return (
    <div>
      { props.policyActiveTabLevel2 && props.policyActiveTabLevel2Subtab !== "paymentcomplaince" ? customColumns && customColumns.length > 0 && (
        <div style={{ marginTop: 10, padding: 4 }}>
          <MaterialReactTable
            columns={customColumns}
            data={props.data}
            enableRowSelection
            onRowSelectionChange={setRowSelection}
            enablePagination={false}
            enableRowNumbers
            enableBottomToolbar={false}
            rowNumberMode="static"
            enableMultiRowSelection={false}
            muiTableBodyRowProps={({ row }) => ({
              onClick: row.getToggleSelectedHandler(),
              sx: { cursor: "pointer" },
            })}
            getRowId={(row) => row.id}
            enableRowActions={false}
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "Actions", //change header text
                // size: 300, //make actions column wider
                muiTableHeadCellProps: {
                  align: "center",
                },
                muiTableBodyCellProps: {
                  align: "center",
                },
              },
            }}
            renderRowActions={({ row, table }) => (
              <div className={styles.wrapper}>
                <Tooltip
                  content="View"
                  relationship="label"
                  positioning="below"
                >
                  <Button
                    appearance="subtle"
                    icon={
                      <Eye16Filled
                        primaryFill="var(--button-color)"
                        onClick={() => {
                          props.handleRowAction("view", row);
                        }}
                      />
                    }
                  ></Button>
                </Tooltip>
                <Tooltip
                  content="Edit"
                  relationship="label"
                  positioning="below"
                >
                  <Button
                    appearance="subtle"
                    icon={
                      <Edit16Filled
                        primaryFill="var(--button-color)"
                        onClick={() => {
                          props.handleRowAction("edit", row);
                        }}
                      />
                    }
                  ></Button>
                </Tooltip>
              </div>
            )}
            initialState={{
              columnPinning: { right: ["mrt-row-actions"] },
            }}
            positionActionsColumn="last"
            positionToolbarAlertBanner="bottom"
            state={{ rowSelection }}
          />
        </div>
      ) : null }
    </div>
  );
};
export default PolicyDetails;
