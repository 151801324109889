import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

export const formatedDatepickerDate = (date: any) => {
  if (date !== undefined && date !== null && date !== "null" && date !== "") {
    let mydate = moment(date, "DD/MM/YYYY");
    return moment(mydate).format("MM/DD/YYYY");
  } else {
    return null;
  }
};

export const formatedDate = (date: any) => {
  if (date !== undefined && date !== null && date !== "null" && date !== "") {
    let frmdate = moment(date).format("DD-MM-YYYY");
    return frmdate;
  } else {
    return "";
  }
};

export const formatedTime = (date: any) => {
  if (date !== undefined && date !== null && date !== "null" && date !== "") {
    // Parse the date as UTC and format the time
    let frmTime = moment.utc(date).format("hh:mm A");
    return frmTime;
  } else {
    return "";
  }
};

export const getSalutations = (values: any) => {
  let salutation = "";
  if (
    values &&
    values !== undefined &&
    Object.keys(values).length !== 0 &&
    values.constructor === Object
  ) {
    if (values.title && values.title !== "") {
      salutation += values.title + ".";
    }
    if (values.first_names && values.first_names !== "") {
      salutation += " " + values.first_names;
    }
    if (values.last_name && values.last_name !== "") {
      salutation += " " + values.last_name + "";
    }
  }
  return salutation;
};

export const checkIfKeyExist = (objectArray: [], keyName: string) => {
  let keyExist = objectArray.find((key: any) => key.context === keyName);
  return keyExist;
};

export const filterArrayByKey = (opts: any, key: string) => {
  let filteredData = opts.filter((item: any) => item.context === key);
  return filteredData && filteredData.length > 0 ? filteredData : [];
};

export const findValueByKey = (opts: any, key: string) => {
  let match = opts.filter((opt: any) => {
    return opt.context === key;
  });
  return match[0] ? match[0].value : null;
};

export const camalize = (str: string) => {
  if (str !== undefined) {
    return str
      .replace(/\s(.)/g, function ($1) {
        return $1.toUpperCase();
      })

      .replace(/^(.)/, function ($1) {
        return $1.toUpperCase();
      });
  } else {
    return "";
  }
};

export const removeEmptyValues = (obj: any) => {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      obj[propName] = "";
    }
  }
  return obj;
};

export const getAssetTypeByCaseType = (caseType: any) => {
  let caseTypeList = {
    "0": "Bank/Building Socities",
    "1": "Share Holdings",
    "2": "Home and Personal",
    "3": "Investment",
  };
  return (caseTypeList as { [key: string]: any })[caseType] !== undefined
    ? (caseTypeList as { [key: string]: any })[caseType]
    : "";
};

export const getAssetTypeByCaseTypeASReq = (caseType: any) => {
  let caseTypeList = {
    "0": "Banks/B Societies",
    "1": "Shares",
    "2": "Home/Personal",
    "3": "Unit-linked",
  };
  return (caseTypeList as { [key: string]: any })[caseType] !== undefined
    ? (caseTypeList as { [key: string]: any })[caseType]
    : "";
};

export const getAssetTypeByCaseTypeId = (caseType: any) => {
  let caseTypeList = {
    "Banks/B Societies": "0",
    Shares: "1",
    "Home/Personal": "2",
    "Unit-linked": "3",
  };
  return (caseTypeList as { [key: string]: any })[caseType] !== undefined
    ? (caseTypeList as { [key: string]: any })[caseType]
    : "";
};

export const getAssetCategoryByCaseType = (caseType: any) => {
  let caseTypeList = {
    "Banks/B Societies": "Bank/Building Socities",
    Shares: "Share Holdings",
    "Home/Personal": "Home and Personal",
    "Unit-linked": "Investment",
  };
  return (caseTypeList as { [key: string]: any })[caseType] !== undefined
    ? (caseTypeList as { [key: string]: any })[caseType]
    : "";
};

export const generateUUID = () => {
  const newUUID = uuidv4();
  console.log("Generated UUID:", newUUID);
  return newUUID;
};

export const getClientType = (typeValue: number) => {
  let clientTypes = {
    "0": "Client",
    "1": "Partner",
    "2": "Joint",
  };
  return (clientTypes as { [key: string]: any })[typeValue] !== undefined
    ? (clientTypes as { [key: string]: any })[typeValue]
    : "";
};

export const getYes_NoConversion = (typeValue: number) => {
  let LinkedToMortgage = {
    "0": "No",
    "1": "Yes",
  };
  return (LinkedToMortgage as { [key: string]: any })[typeValue] !== undefined
    ? (LinkedToMortgage as { [key: string]: any })[typeValue]
    : "";
};

export const getClientCat = (typeValue: number) => {
  let clientTypes = {
    Client: "0",
    Partner: "1",
    Joint: "2",
  };
  return (clientTypes as { [key: string]: any })[typeValue] !== undefined
    ? (clientTypes as { [key: string]: any })[typeValue]
    : "";
};

export const getClientNoteCategories = (categoryId: number) => {
  let noteCategories = {
    0: "Standard Note",
    1: "Letter Produced",
    2: "Email Sent",
    3: "Email Saved",
    4: "Report Exported",
    5: "Mail Merge",
    7: "Fact-find - Dependants",
    8: "Fact-find - Incomes",
    9: "Fact-find - Outgoings",
    10: "Fact-find - Employments",
    11: "Fact-find - Assets",
    12: "Fact-find - Liabilities",
    13: "Fact-find - Policies",
    14: "Fact-find - Attitude to Risk",
    15: "Fact-find - Objectives",
    16: "Fact-find - Summary",
    18: "Fact-find - Pensions",
    19: "Fact-find - Investments",
    20: "Fact-find - Protection",
    21: "Fact-find - Mortgages",
    22: "Document Uploaded",
    23: "Document Downloaded",
  };
  return (noteCategories as { [key: string]: any })[categoryId] !== undefined
    ? (noteCategories as { [key: string]: any })[categoryId]
    : "";
};

export const getAssetFundCodeSource = (categoryId: number) => {
  let codeSources = {
    0: "Undefined",
    1: "MEX",
    2: "SEDOL",
    3: "Product Provider Specific",
    4: "ISIN",
  };
  return (codeSources as { [key: string]: any })[categoryId] !== null
    ? (codeSources as { [key: string]: any })[categoryId]
    : "";
};

export const getToken = () => {
  return localStorage.getItem("assystUserToken");
};

export const removeToken = () => {
  localStorage.removeItem("assystUserToken");
};

export const setToken = (val: any) => {
  localStorage.setItem("assystUserToken", val);
};
export const removeRefreshToken = () => {
  localStorage.removeItem("assystUserRefreshToken");
};
export const setRefreshToken = (val: any) => {
  localStorage.setItem("assystUserRefreshToken", val);
};
export const setUserId = (val: any) => {
  return localStorage.setItem("assystUserId", val);
};
export const getUserId = () => {
  return localStorage.getItem("assystUserId");
};
export const getRefreshToken = () => {
  return localStorage.getItem("assystUserRefreshToken");
};
export const removeUserId = () => {
  localStorage.removeItem("assystUserId");
};
// declare all characters
const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export const generateString = (length: number) => {
  let result = " ";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const checkUserPermission = (loggedUser: any, permission: string) => {
  if (
    loggedUser !== undefined &&
    loggedUser.userData !== undefined &&
    loggedUser.userData.realm_access !== undefined &&
    loggedUser.userData.realm_access.roles !== undefined &&
    loggedUser.userData.realm_access.roles.length > 0
  ) {
    if (loggedUser.userData.realm_access.roles.includes(permission)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
export const getPolicyCategoryByCaseType = (caseType: any) => {
  let caseTypeList = {
    "Life Assurance": "Life Assurance",
    Pensions: "Pensions",
    Investments: "Investments",
    "Savings Plans": "Savings Plans",
    "Income Protection": "Income Protection",
    "Health Assurance": "Health Assurance",
    General: "General",
  };
  return (caseTypeList as { [key: string]: any })[caseType] !== undefined
    ? (caseTypeList as { [key: string]: any })[caseType]
    : "";
};

export const getpolicyCategoryByCaseType = (caseType: any) => {
  let caseTypeList = {
    "Life Assurance": "Life Assurance",
    Pensions: "Pensions",
    Investments: "Investments",
    "Savings Plans": "Savings Plans",
    "Income Protection": "Income Protection",
    "Health Assurance": "Health Assurance",
    General: "General",
  };
  return (caseTypeList as { [key: string]: any })[caseType] !== undefined
    ? (caseTypeList as { [key: string]: any })[caseType]
    : "";
};

export const formatedDate2 = (date: any) => {
  if (date !== undefined && date !== null && date !== "") {
    let frmdate = moment(date).format("YYYY-MM-DD");
    return frmdate;
  } else {
    return "";
  }
};
export const getUserAvatharName = (name: any) => {
  const nameParts = name.split(" ");
  const firstNameInitial = nameParts[0] ? nameParts[0][0] : "";
  const lastNameInitial = nameParts[1] ? nameParts[1][0] : "";
  return firstNameInitial + lastNameInitial;
};
export const isObjectEmpty = (object: any) => {
  return Object.keys(object).length === 0;
};
export const getCommissionCategory = (category: any) => {
  switch (category) {
    case 0:
      return "Generic";
    case 1:
      return "Case-related";
    case 2:
      return "Other Fees/Charges";
    default:
      return "";
  }
};
export const getUserDefinedType = (category: any) => {
  switch (category) {
    case 0:
      return "String";
    case 1:
      return "Integer";
    case 2:
      return "Real";
    case 3:
      return "Date";
    default:
      return "";
  }
};

export const getAmountType = (category: any) => {
  switch (category) {
    case 0:
      return "Money";
      break;
    case 1:
      return "Percent";
      break;

    default:
      return "";
      break;
  }
};
export const convertStringToArray = (data?: string) => {
  let convertedString: any = [];
  if (data != undefined) {
    convertedString = data.split(",");
  }
  // console.log("convertedString",convertedString)

  return convertedString;
};
export const isObjectValue = (variable: any) => {
  return (
    variable !== null &&
    typeof variable === "object" &&
    !Array.isArray(variable)
  );
};
export const getFileNameFromUrl = (url: string) => {
  if (url != undefined && url !== "") {
    return url.substring(url.lastIndexOf("/") + 1);
  } else {
    return "";
  }
};
export const formatCondition = (condition: string): string => {
  try {
    const parsedCondition: any = JSON.parse(condition);
    const {
      tableOne: {
        label: fieldNameOne,
        fieldValue: fieldValueOne,
        condition: conditionOne,
      },
      tableTwo,
      condition: overallCondition,
    } = parsedCondition;

    const tableOnePart = `${fieldNameOne} ${conditionOne} ${fieldValueOne}`;
    let tableTwoPart = "";
    let conditionPart = "";

    if (tableTwo) {
      const {
        label: fieldNameTwo,
        fieldValue: fieldValueTwo,
        condition: conditionTwo,
      } = tableTwo;
      tableTwoPart = `${fieldNameTwo} ${conditionTwo} ${fieldValueTwo}`;
    }

    if (overallCondition) {
      conditionPart = ` ${overallCondition} `;
    }

    return tableTwoPart
      ? `${tableOnePart}${conditionPart}${tableTwoPart}`
      : tableOnePart;
  } catch (error) {
    console.error("Error parsing condition:", error);
    return "Invalid condition";
  }
};

export const isNullOrEmpty = (value: any): boolean => {
  if (value === null || value === undefined) {
    return true;
  }
  if (typeof value === 'string' && value.trim() === '') {
    return true;
  }
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }
  if (typeof value === 'object' && value !== null && Object.keys(value).length === 0) {
    return true;
  }
  return false;
};
