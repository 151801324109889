import {
  Button,
  Caption1Strong,
  Field,
  Input,
  Label,
  Toast,
  ToastBody,
  Toaster,
  ToastTitle,
  useId,
  useToastController,
} from "@fluentui/react-components";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Edit24Filled } from "@fluentui/react-icons";

import * as Yup from "yup";
import apiServices from "../../service";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { getUserProfileInfo } from "../../redux/authSlice";
import ASCButton from "../../components/Button";
import "./style.css";

function Account(props: any) {
  const authUser = useSelector((state: RootState) => state.authUser);
  const dispatch: AppDispatch = useDispatch();

  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const [formField, setFormField] = useState({
    full_name: "",
    short_name: "",
    email: "",
    prefix: "",
    domain: "",
  });
  useEffect(() => {
    if (authUser) {
      getDetail(authUser?.userId);
    }
  }, []);
  const formik = useFormik({
    initialValues: {
      full_name: formField?.full_name ? formField.full_name : "",
      short_name: formField?.short_name ? formField.short_name : "",

      emailPrefix: formField?.prefix ? formField.prefix : "",
    },
    validationSchema: Yup.object({
      full_name: Yup.string()
        .required("Name is required")
        .max(20, "Full Name cannot be longer than 20 characters"),
      short_name: Yup.string()
        .required("Short name is required")
        .max(20, "Short Name cannot be longer than 20 characters"),
      // emailPrefix: Yup.string().required("Email is required"),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      // Handle form submission logic
      // console.log(values);
      updateProfile(values);
    },
  });
  const getDomain = (inputEmail: string) => {
    const atIndex = inputEmail.indexOf("@");
    if (atIndex !== -1) {
      const prefix = inputEmail.substring(0, atIndex);
      const domain = inputEmail.substring(atIndex + 1);

      return { prefix: prefix, domain: domain };
    } else {
      return { prefix: "", domain: "" };
    }
  };
  const getDetail = (userId: any) => {
    apiServices.userProfile
      .getDetail(userId)
      .then((response) => {
        if (response.data.data) {
          setFormField((prev) => ({
            ...prev,
            full_name: response.data.data.full_name,
            short_name: response.data.data.short_name,
            email: response.data.data.user_email,
            prefix: response.data.data.user_email
              ? getDomain(response.data.data.user_email).prefix
              : "",
            domain: response.data.data.user_email
              ? getDomain(response.data.data.user_email).domain
              : "",
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateProfile = (values: any) => {
    const { full_name, short_name, emailPrefix } = values;
    let reqBody = {
      user_email: emailPrefix + "@" + formField.domain,
      full_name: full_name,
      short_name: short_name,
    };
    if (authUser?.userId && authUser?.userId !== "") {
      apiServices.userProfile
        .updateDetail(authUser?.userId, reqBody)
        .then((response) => {
          if (response.data.isError === false) {
            getDetail(authUser?.userId);
            props.notify(response.data.message, "", "success");
            props.setMode("view");
            dispatch(getUserProfileInfo(authUser?.userId));
          } else {
            props.notify("Something went wrong", "", "error");
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <>
      <Toaster toasterId={toasterId} />
      <div className="profile_content">
        <div>
          <h2>Account</h2>
          <div className="profile_view">
            <Label>Full Name</Label>
            <>
              {props.mode === "view" && <Label>{formField.full_name}</Label>}
              {props.mode === "edit" && (
                <Field>
                  <Input
                    name={"full_name"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.full_name}
                  />
                  {formik.errors.full_name && (
                    <span className="form_error_msg">
                      {formik.errors.full_name}
                    </span>
                  )}
                </Field>
              )}</>
            <Label>Short Name</Label>
            <>{props.mode === "view" && <Label>{formField.short_name}</Label>}
              {props.mode === "edit" && (
                <Field>
                  <Input
                    name={"short_name"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.short_name}
                  />
                  {formik.errors.short_name && (
                    <span className="form_error_msg">
                      {formik.errors.short_name}
                    </span>
                  )}
                </Field>
              )}</>
            <Label>Email</Label>
            <>{props.mode === "view" && <Label>{formField.email}</Label>}
              {props.mode === "edit" && (
                <Field>
                  <Input
                    name={"email"}
                    disabled={true}
                    onBlur={formik.handleBlur}
                    value={formField.email}
                  />
                  {formik.errors.emailPrefix && (
                    <span className="form_error_msg">
                      {formik.errors.emailPrefix}
                    </span>
                  )}
                </Field>
              )}
            </>
          </div>
          {props.mode === "edit" && (
            <div className="action_buttons">
              <Button
                onClick={() => {
                  props.setMode("view");
                }}
              >
                Cancel
              </Button>
              <ASCButton
                shape={"rounded"}
                label={"Save"}
                className="asc-button-primary"
                appearance="primary"
                onItemClick={() => formik.handleSubmit()}
                size="medium"
              />
            </div>
          )}
        </div>
        <div>
          {props.mode === "view" && (
            <Button
              onClick={() => {
                props.setMode("edit");
              }}
              icon={<Edit24Filled />}
            >
              Edit
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export default Account;
