import React from "react";
import { useParams } from "react-router-dom";
import Account from "./account";
import CommunicationDetail from "./communicationDetail";
import { Toast, Link,ToastBody, Toaster, ToastTitle, ToastTrigger, useId, useToastController } from "@fluentui/react-components";

function ProfileRouting(props: any) {
  const { section } = useParams();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const notify = (title?: string, message?: string, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
         action={
          <ToastTrigger>
            <Link>Dismiss</Link>
          </ToastTrigger>
        }
        >{title ?? title}</ToastTitle>
        <ToastBody>{message ?? message}</ToastBody>
      </Toast>,
      {
        intent: type ? type : "success",
        position: "top-end",
        timeout: type && type === "error" ? 2000 : 2000,
      }
    );
    
  return (
    <>
      <Toaster toasterId={toasterId} />
      {section === "account" && (
        <Account
          mode={props.mode}
          setMode={props.setMode}
          notify={notify}
        />
      )}
      {section === "emailSettings" && <CommunicationDetail mode={props.mode}
          setMode={props.setMode}
          notify={notify} />}
    </>
  );
}

export default ProfileRouting;
