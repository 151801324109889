import React, { useEffect, useMemo } from "react";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_RowSelectionState,
} from "material-react-table";
import {
  Eye16Filled,
  Attach20Regular,
  Edit16Filled,
} from "@fluentui/react-icons";
import {
  Button,
  makeStyles,
  Tooltip,
  shorthands,
} from "@fluentui/react-components";
import {
  formatedDate,
  formatedTime,
  getClientNoteCategories,
  getClientType,
  getYes_NoConversion,
} from "../utils";
import ReactQuillComponent from "./ReactQuillComponent";
import apiServices from "../service";
const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    cursor: "pointer",
  },
  contentHeader: {
    marginTop: "0",
  },
  scopeArea: {
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
  },
});

type ComponentProps = {
  style?: React.CSSProperties;
  [key: string]: any;
};

const downloadFile = (documentId: string, customerId: string) => {
  if (!documentId) return;

  apiServices.document.getDocument(documentId, customerId)
    .then((res: any) => {
      if (res.data && res.data.data) {
        const url = res.data.data;
        const link = document.createElement("a");
        link.href = url;
        link.download = 'document'; // You can set a default name for the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
    .catch((err) => {
      console.error('Error downloading file:', err);
    });
};


const tableColumnList = {
  CLIENT_EMPLOYMENT: [
    {
      accessorKey: "occupation",
      header: "Occupation",
    },
    {
      accessorKey: "employment_type",
      header: "Employment Type",
    },
    {
      accessorKey: "employment_basis",
      header: "Employment Basis",
    },
    {
      accessorKey: "principal_employment",
      header: "Principal Employment ",
    },
    {
      accessorKey: "employer_name",
      header: "Employer Name",
    },
    {
      accessorKey: "address_1",
      header: "Employer Address",
    },

    {
      accessorKey: "town",
      header: "Town",
    },
    {
      accessorKey: "county",
      header: "County",
    },
    {
      accessorKey: "country",
      header: "Country",
    },
    {
      accessorKey: "postcode",
      header: "Post Code",
    },
    {
      accessorKey: "phone",
      header: "Phone",
    },
    {
      accessorKey: "fax",
      header: "Fax",
    },
    {
      accessorKey: "start_date",
      header: "Start Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
    },
    {
      accessorKey: "end_date",
      header: "End Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,
    },
    {
      accessorKey: "salary_review_date",
      header: "Salary Review",
      Cell: ({ row }: any) => (
        <>{formatedDate(row.original.salary_review_date)}</>
      ),
    },
    {
      accessorKey: "auto_enrolment",
      header: "Automatic Entrolment",
    },
    {
      accessorKey: "retirement_age",
      header: "Retirement Age",
    },
    {
      accessorKey: "position",
      header: "Position",
    },
    {
      accessorKey: "payroll_id",
      header: "Payroll Id",
    },
    {
      accessorKey: "controlling_director",
      header: "Controlling Director?",
      Cell: ({ row }: any) => (
        <>{getYes_NoConversion(row.original.controlling_director)}</>
      ),
    },
    {
      accessorKey: "shareholding",
      header: "Shareholding",
    },
    {
      accessorKey: "note_text",
      header: "Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
    {
      accessorKey: "death_in_service",
      header: "Death In Service",
      Cell: ({ row }: any) =>
        `${getYes_NoConversion(row.original.death_in_service_yn)}${
          row.original.death_in_service
        }`,
    },
    {
      accessorKey: "sick_pay",
      header: "Sick Pay",
      Cell: ({ row }: any) =>
        `${getYes_NoConversion(row.original.sick_pay_yn)} ${
          row.original.sick_pay
        }`,
    },
    {
      accessorKey: "pension",
      header: "Pension",
      Cell: ({ row }: any) =>
        `${getYes_NoConversion(row.original.pension_yn)} ${
          row.original.pension
        }`,
    },
    {
      accessorKey: "pmi",
      header: "PMI",
      Cell: ({ row }: any) =>
        `${getYes_NoConversion(row.original.pmi_yn)} ${row.original.pmi}`,
    },
    {
      accessorKey: "phi",
      header: "PHI",
      Cell: ({ row }: any) =>
        `${getYes_NoConversion(row.original.phi_yn)} ${row.original.phi}`,
    },
    {
      accessorKey: "critical_illness",
      header: "Critical Illness",
      Cell: ({ row }: any) =>
        `${getYes_NoConversion(row.original.critical_illness_yn)} ${
          row.original.critical_illness
        }`,
    },
    {
      accessorKey: "company_car",
      header: "Company Car",
      Cell: ({ row }: any) =>
        `${getYes_NoConversion(row.original.company_car_yn)} ${
          row.original.company_car
        }`,
    },
    {
      accessorKey: "share_options",
      header: "Share Options",
      Cell: ({ row }: any) =>
        `${getYes_NoConversion(row.original.share_options_yn)} ${
          row.original.share_options
        }`,
    },
    {
      accessorKey: "other",
      header: "Other Options",
      Cell: ({ row }: any) =>
        `${getYes_NoConversion(row.original.other_yn)} ${row.original.other}`,
    },
  ],

  CLIENT_ATTITUDE_TO_RISK: [
    {
      accessorKey: "category",
      header: "Category",
    },
    {
      accessorKey: "category_description",
      header: "Category Description",
    },
    {
      accessorKey: "rating",
      header: "Rating",
    },
    {
      accessorKey: "rating_description",
      header: "Rating Description",
    },
  ],
  CLIENT_IDENTITY: [
    {
      accessorKey: "category",
      header: "Category",
    },
    {
      accessorKey: "item",
      header: "Item",
    },
    {
      accessorKey: "reference",
      header: "Reference",
    },
    {
      accessorKey: "issued",
      header: "Issued",
      Cell: ({ row }: any) => <>{formatedDate(row.original.issued)}</>,
    },
    {
      accessorKey: "expires",
      header: "Expires",
      Cell: ({ row }: any) => <>{formatedDate(row.original.expires)}</>,
    },
    {
      accessorKey: "issued_at",
      header: "Issued at",
    },
    {
      accessorKey: "seen",
      header: "Seen",
      Cell: ({ row }: any) => <>{formatedDate(row.original.seen)}</>,
    },
  ],
  CLIENT_OBJECTIVES: [
    {
      accessorKey: "sort_order",
      header: "Sort Order",
    },
    {
      accessorKey: "objective_description",
      header: "Objective",
    },
    {
      accessorKey: "applicable",
      header: "Applicable",
      Cell: ({ row }: any) => (
        <>{row.original.applicable === 1 ? "Yes" : "No"}</>
      ),
    },

    {
      accessorKey: "rating",
      header: "Rating",
    },
    {
      accessorKey: "comments",
      header: "Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.comments} />
        </>
      ),
    },
  ],
  CLIENT_SERVICE_TYPE: [
    {
      accessorKey: "description",
      header: "Service Type",
    },
    {
      accessorKey: "start_date",
      header: "Start Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
    },

    {
      accessorKey: "end_date",
      header: "End Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,
    },
  ],
  COMMISSION_SERVICE_TYPE: [
    {
      accessorKey: "description",
      header: "Service Type",
    },
    {
      accessorKey: "start_date",
      header: "Start Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
    },

    {
      accessorKey: "end_date",
      header: "End Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,
    },
  ],
  COMMISSION_PARTNER_SERVICE_TYPE: [
    {
      accessorKey: "description",
      header: "Service Type",
    },
    {
      accessorKey: "start_date",
      header: "Start Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
    },

    {
      accessorKey: "end_date",
      header: "End Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,
    },
  ],
  CLIENT_INCOME: [
    {
      accessorKey: "sort_order",
      header: "Sort Order",
    },
    {
      accessorKey: "owner",
      header: "Owner",
      Cell: ({ row }: any) => <>{getClientType(row.original.owner)}</>,
    },
    {
      accessorKey: "income_type",
      header: "Type",
    },
    {
      accessorKey: "gross_amount",
      header: "Gross Amount",
    },
    {
      accessorKey: "net_amount",
      header: "Net Amount",
    },
    {
      accessorKey: "frequency",
      header: "Frequency",
    },
    {
      accessorKey: "source",
      header: "Source",
    },
    {
      accessorKey: "start_date",
      header: "Start Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
    },
    {
      accessorKey: "end_date",
      header: "End Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,
    },
    {
      accessorKey: "note_text",
      header: "Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
  ],
  CLIENT_DEPENDANT: [
    {
      accessorKey: "title",
      header: "Title",
    },
    {
      accessorKey: "first_names",
      header: "First Name(s)",
    },
    {
      accessorKey: "last_name",
      header: "Last Name",
    },
    {
      accessorKey: "known_as",
      header: "Known as",
    },
    {
      accessorKey: "sex",
      header: "Sex",
    },
    {
      accessorKey: "date_of_birth",
      header: "Date of Birth",
      Cell: ({ row }: any) => <>{formatedDate(row.original.date_of_birth)}</>,
    },
    // {
    //   accessorKey: "dependants.age_next",
    //   header: "Age Next",
    // },
    {
      accessorKey: "relation",
      header: "Relation to Client",
    },
    {
      accessorKey: "parent",
      header: "Parent",
    },
    {
      accessorKey: "smoker",
      header: "Smoker",
    },
    {
      accessorKey: "living_at_home",
      header: "Living at Home",
    },
    {
      accessorKey: "financially_dependant",
      header: "Financially Dependant",
    },
    {
      accessorKey: "note_text",
      header: "Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
  ],
  CLIENT_OUTGOINGS: [
    {
      accessorKey: "sort_order",
      header: "Sort Order",
    },
    {
      accessorKey: "description",
      header: "Description",
    },
    {
      accessorKey: "frequency",
      header: "Frequency",
    },
    {
      accessorKey: "discretionary",
      header: "Type",
    },

    {
      accessorKey: "client_amount",
      header: "Client Amount",
    },
    {
      accessorKey: "partner_amount",
      header: "Partner Amount",
    },
    {
      accessorKey: "joint_amount",
      header: "Joint Amount",
    },
  ],
  ADDRESS_BOOK: [
    {
      accessorKey: "address_type",
      header: "Address Type",
    },
    {
      accessorKey: "organisation",
      header: "Organisation",
    },
    {
      accessorKey: "contact_name",
      header: "Contact Name",
    },
    {
      accessorKey: "address_1",
      header: "Address",
      Cell: ({ row }: any) => (
        <p>
          {row.original.address_1}
          <br />
          {row.original.address_2}
          <br />
          {row.original.address_3}
        </p>
      ),
    },
    {
      accessorKey: "town",
      header: "Town",
    },
    {
      accessorKey: "county",
      header: "County",
    },
    {
      accessorKey: "country",
      header: "Country",
    },
    {
      accessorKey: "postcode",
      header: "Postcode",
    },
    {
      accessorKey: "telephone",
      header: "Telephone",
    },
    {
      accessorKey: "home_phone",
      header: "Home Phone",
    },
    {
      accessorKey: "work_phone",
      header: "Work Phone",
    },
    {
      accessorKey: "mobile",
      header: "Mobile",
    },
    {
      accessorKey: "email",
      header: "Email",
    },
    {
      accessorKey: "work_email",
      header: "Work Email",
    },
    {
      accessorKey: "effective_date",
      header: "Effective Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.effective_date)}</>,
    },
    {
      accessorKey: "note_text",
      header: "Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
  ],
  CONTACTS_NOTES: [
    {
      accessorKey: "note_date",
      header: "Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.note_date)}</>,
    },
    {
      accessorKey: "note_time",
      header: "Time",
      Cell: ({ row }: any) => <>{formatedTime(row.original.note_time)}</>,
    },
    {
      accessorKey: "note_type",
      header: "Type",
    },

    {
      accessorKey: "note_text",
      header: "Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
    {
      accessorKey: "category",
      header: "Category",
      Cell: ({ row }: any) => (
        <>{getClientNoteCategories(row.original.category)}</>
      ),
    },
    {
      accessorKey: "file_link",
      header: "File Link",
      Cell: ({ row }: any) => {
        return (
          <div>
            {row.original.file_link ? (
              <span
                onClick={() => {
                  downloadFile(row.original.file_link, row.original.customer_id);
                }}
              >
               <Attach20Regular />
              </span>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
  ],
  FACT_FIND_NOTES: [
    {
      accessorKey: "category",
      header: "Category",
      Cell: ({ row }: any) => (
        <>{getClientNoteCategories(row.original.category)}</>
      ),
    },
    {
      accessorKey: "note_date",
      header: "Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.note_date)}</>,
    },
    {
      accessorKey: "note_time",
      header: "Time",
      Cell: ({ row }: any) => <>{formatedTime(row.original.note_time)}</>,
    },
    {
      accessorKey: "note_type",
      header: "Type",
    },
    {
      accessorKey: "note_text",
      header: "Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
    {
      accessorKey: "file_link",
      header: "File Link",
      Cell: ({ row }: any) => {
        return (
          <div>
            {row.original.file_link ? (
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => downloadFile(row.original.file_link, row.original.customer_id)}
              >
                <Attach20Regular /> {/* Ensure this is a valid component */}
              </span>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
  ],
  CONTACTS_TIME_ALLOCATION: [
    {
      accessorKey: "allocated_date",
      header: "Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.allocated_date)}</>,
    },
    {
      accessorKey: "duration",
      header: "Duration",
      Cell: ({ row }: any) => <>{formatedTime(row.original.duration)}</>,
    },
    {
      accessorKey: "note_text",
      header: "Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
    {
      accessorKey: "resource",
      header: "Resource",
    },
  ],
  CONTACTS_CLIENT_ACTION: [
    {
      accessorKey: "appointment_date",
      header: "Date",
      Cell: ({ row }: any) => (
        <>{formatedDate(row.original.appointment_date)}</>
      ),
    },
    {
      accessorKey: "appointment_time",
      header: "Time",
      Cell: ({ row }: any) => (
        <>{formatedTime(row.original.appointment_time)}</>
      ),
    },
    {
      accessorKey: "appointment_type",
      header: "Type",
    },
    {
      accessorKey: "appointment_note",
      header: "Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.appointment_note} />
        </>
      ),
    },
    {
      accessorKey: "appointment_assigned",
      header: "Assigned",
    },
    {
      accessorKey: "appointment_status",
      header: "Status",
    },
  ],
  FEES_CHARGES: [
    {
      accessorKey: "commission_type",
      header: "Fee Type",
    },
    {
      accessorKey: "payment_source",
      header: "Source of Payment",
    },
    {
      accessorKey: "amount_to_company",
      header: "Amount to Company",
    },
    {
      accessorKey: "frequency",
      header: "Frequency",
    },
    {
      accessorKey: "start_date",
      header: "Start Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
    },
    {
      accessorKey: "end_date",
      header: "End Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,
    },
    {
      accessorKey: "payment_count",
      header: "Number of Payments",
    },
    {
      accessorKey: "consultant",
      header: "Consultant",
    },
    {
      accessorKey: "note_text",
      header: "Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
  ],
  PARTNER_EMPLOYMENT: [
    {
      accessorKey: "occupation",
      header: "Occupation",
    },
    {
      accessorKey: "employment_type",
      header: "Employment Type",
    },
    {
      accessorKey: "employment_basis",
      header: "Employment Basis",
    },
    {
      accessorKey: "principal_employment",
      header: "Principal Employment ",
    },
    {
      accessorKey: "employer_name",
      header: "Employer Name",
    },
    {
      accessorKey: "address_1",
      header: "Employer Address",
    },

    {
      accessorKey: "town",
      header: "Town",
    },
    {
      accessorKey: "county",
      header: "County",
    },
    {
      accessorKey: "country",
      header: "Country",
    },
    {
      accessorKey: "postcode",
      header: "Post Code",
    },
    {
      accessorKey: "phone",
      header: "Phone",
    },
    {
      accessorKey: "fax",
      header: "Fax",
    },
    {
      accessorKey: "start_date",
      header: "Start Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
    },
    {
      accessorKey: "end_date",
      header: "End Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,
    },
    {
      accessorKey: "salary_review_date",
      header: "Salary Review",
      Cell: ({ row }: any) => (
        <>{formatedDate(row.original.salary_review_date)}</>
      ),
    },
    {
      accessorKey: "auto_enrolment",
      header: "Automatic Entrolment",
    },
    {
      accessorKey: "retirement_age",
      header: "Retirement Age",
    },
    {
      accessorKey: "position",
      header: "Position",
    },
    {
      accessorKey: "payroll_id",
      header: "Payroll Id",
    },
    {
      accessorKey: "controlling_director",
      header: "Controlling Director?",
      Cell: ({ row }: any) => (
        <>{getYes_NoConversion(row.original.controlling_director)}</>
      ),
    },
    {
      accessorKey: "shareholding",
      header: "Shareholding",
    },
    {
      accessorKey: "note_text",
      header: "Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
    {
      accessorKey: "death_in_service",
      header: "Death In Service",
    },
    {
      accessorKey: "sick_pay",
      header: "Sick Pay",
    },
    {
      accessorKey: "pension",
      header: "Pension",
    },
    {
      accessorKey: "pmi",
      header: "PMI",
    },
    {
      accessorKey: "phi",
      header: "PHI",
    },
    {
      accessorKey: "critical_illness",
      header: "Critical Illness",
    },
    {
      accessorKey: "company_car",
      header: "Company Car",
    },
    {
      accessorKey: "share_options",
      header: "Share Options",
    },
    {
      accessorKey: "other",
      header: "Other Options",
    },
  ],

  PARTNER_ATTITUDE_TO_RISK: [
    {
      accessorKey: "category",
      header: "Category",
    },
    {
      accessorKey: "category_description",
      header: "Category Description",
    },
    {
      accessorKey: "rating",
      header: "Rating",
    },
    {
      accessorKey: "rating_description",
      header: "Rating Description",
    },
  ],
  PARTNER_IDENTITY: [
    {
      accessorKey: "category",
      header: "Category",
    },
    {
      accessorKey: "item",
      header: "Item",
    },
    {
      accessorKey: "reference",
      header: "Reference",
    },
    {
      accessorKey: "issued",
      header: "Issued",
      Cell: ({ row }: any) => <>{formatedDate(row.original.issued)}</>,
    },
    {
      accessorKey: "expires",
      header: "Expires",
      Cell: ({ row }: any) => <>{formatedDate(row.original.expires)}</>,
    },
    {
      accessorKey: "issued_at",
      header: "Issued at",
    },
    {
      accessorKey: "seen",
      header: "Seen",
      Cell: ({ row }: any) => <>{formatedDate(row.original.seen)}</>,
    },
  ],
  PARTNER_OBJECTIVES: [
    {
      accessorKey: "sort_order",
      header: "Sort Order",
    },
    {
      accessorKey: "objective_description",
      header: "Objective",
    },
    {
      accessorKey: "applicable",
      header: "Applicable",
      Cell: ({ row }: any) => (
        <>{row.original.applicable === 1 ? "Yes" : "No"}</>
      ),
    },

    {
      accessorKey: "rating",
      header: "Rating",
    },
    {
      accessorKey: "comments",
      header: "Comments",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.comments} />
        </>
      ),
    },
  ],
  PARTNER_SERVICE_TYPE: [
    {
      accessorKey: "description",
      header: "Service Type",
    },
    {
      accessorKey: "start_date",
      header: "Start Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
    },

    {
      accessorKey: "end_date",
      header: "End Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,
    },
  ],
  PARTNER_INCOME: [
    {
      accessorKey: "sort_order",
      header: "Sort Order",
    },
    {
      accessorKey: "owner",
      header: "Owner",
      Cell: ({ row }: any) => <>{getClientType(row.original.owner)}</>,
    },
    {
      accessorKey: "income_type",
      header: "Type",
    },
    {
      accessorKey: "gross_amount",
      header: "Gross",
    },
    {
      accessorKey: "net_amount",
      header: "Net",
    },
    {
      accessorKey: "frequency",
      header: "Frequency",
    },
    {
      accessorKey: "source",
      header: "Source",
    },
  ],
  PARTNER_DEPENDANT: [
    {
      accessorKey: "title",
      header: "Title",
    },
    {
      accessorKey: "first_names",
      header: "First Name(s)",
    },
    {
      accessorKey: "last_name",
      header: "Last Name",
    },
    {
      accessorKey: "known_as",
      header: "Known as",
    },
    {
      accessorKey: "sex",
      header: "Sex",
    },
    {
      accessorKey: "date_of_birth",
      header: "Date of Birth",
      Cell: ({ row }: any) => <>{formatedDate(row.original.date_of_birth)}</>,
    },
    // {
    //   accessorKey: "dependants.age_next",
    //   header: "Age Next",
    // },
    {
      accessorKey: "relation",
      header: "Relation to Client",
    },
    {
      accessorKey: "parent",
      header: "Parent",
    },
    {
      accessorKey: "smoker",
      header: "Smoker",
    },
    {
      accessorKey: "living_at_home",
      header: "Living at Home",
    },
    {
      accessorKey: "financially_dependant",
      header: "Financially Dependant",
    },
    {
      accessorKey: "note_text",
      header: "Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
  ],
  PARTNER_OUTGOINGS: [
    {
      accessorKey: "sort_order",
      header: "Sort Order",
    },
    {
      accessorKey: "description",
      header: "Outgoing",
    },
    {
      accessorKey: "frequency",
      header: "Frequency",
    },
    {
      accessorKey: "discretionary",
      header: "Type",
    },

    {
      accessorKey: "client_amount",
      header: "Client Amount",
    },
    {
      accessorKey: "partner_amount",
      header: "Partner Amount",
    },
    {
      accessorKey: "joint_amount",
      header: "Joint Amount",
    },
  ],
  MASTER_SETTINGS_INCOME_CATEGORY: [
    {
      accessorKey: "sort_order",
      header: "Sort Order",
    },
    {
      accessorKey: "description",
      header: "Description",
    },
    {
      accessorKey: "frequency",
      header: "Frequency",
    },
  ],
};

const TableDataList = (props: ComponentProps) => {
  
  const styles = useStyles();
  const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>(
    {}
  );
  const columns = useMemo<MRT_ColumnDef<{ [key: string]: any }>[]>(
    () => [],
    []
  );
  const customColumns = useMemo<MRT_ColumnDef<{ [key: string]: any }>[]>(() => {
    if (props.context !== "") {
      return (tableColumnList as { [key: string]: any })[props.context];
    }
    return [];
  }, [props.context]);
  React.useEffect(() => {
    if (
      props.handleRowAction !== undefined &&
      props.data.length > 0 &&
      Object.keys(rowSelection)[0] !== undefined
    ) {
      let selectedRow = props.data[Object.keys(rowSelection)[0]];
      if (selectedRow) {
        if (props.isContactHistory) {
          props.handleRowAction("edit", selectedRow);
        } else {
          props.handleRowAction("view", selectedRow);
        }
      }
      if (
        props.handleRowAction !== undefined &&
        props.data.length > 0 &&
        Object.keys(rowSelection)[0] !== undefined
      ) {
        let selectedRow = props.data[Object.keys(rowSelection)[0]];
        if (selectedRow) {
          if (props.isContactHistory) {
            props.handleRowAction("edit", selectedRow);
          } else {
            props.handleRowAction("view", selectedRow);
          }
        }
        // props.handleRowSelection(Object.keys(rowSelection)[0]);
      }
    }
  }, [rowSelection]);
  useEffect(() => {
    if (
      props.selectedRowItem &&
      Object.keys(props.selectedRowItem).length !== 0 &&
      props.data.length > 0
    ) {
      let index = 0;

      if (props.context === "CLIENT_SERVICE_TYPE") {
        index = props.data.findIndex(
          (x: any) => x.servicetype_id === props.selectedRowItem.servicetype_id
        );
      } else if (props.context === "COMMISSION_SERVICE_TYPE") {
        index = props.data.findIndex(
          (x: any) => x.servicetype_id === props.selectedRowItem.servicetype_id
        );
      } else if (props.context === "COMMISSION_PARTNER_SERVICE_TYPE") {
        index = props.data.findIndex(
          (x: any) => x.servicetype_id === props.selectedRowItem.servicetype_id
        );
      } else if (props.context === "CLIENT_OBJECTIVES") {
        index = props.data.findIndex(
          (x: any) => x.objective_id === props.selectedRowItem.objective_id
        );
      } else if (props.context === "CLIENT_IDENTITY") {
        index = props.data.findIndex(
          (x: any) => x.identity_id === props.selectedRowItem.identity_id
        );
      } else if (props.context === "CLIENT_EMPLOYMENT") {
        index = props.data.findIndex(
          (x: any) => x.employment_id === props.selectedRowItem.employment_id
        );
      } else if (props.context === "CLIENT_DEPENDANT") {
        index = props.data.findIndex(
          (x: any) => x.dependant_id === props.selectedRowItem.dependant_id
        );
      } else if (props.context === "CLIENT_INCOME") {
        index = props.data.findIndex(
          (x: any) => x.income_id === props.selectedRowItem.income_id
        );
      } else if (props.context === "CLIENT_OUTGOINGS") {
        index = props.data.findIndex(
          (x: any) => x.expense_id === props.selectedRowItem.expense_id
        );
      } else if (props.context === "ADDRESS_BOOK") {
        index = props.data.findIndex(
          (x: any) => x.address_id === props.selectedRowItem.address_id
        );
      } else if (props.context === "CLIENT_HISTORY") {
        index = props.data.findIndex(
          (x: any) => x.note_id === props.selectedRowItem.note_id
        );
      } else if (props.context === "FACT_FIND_NOTES") {
        index = props.data.findIndex(
          (x: any) => x.note_id === props.selectedRowItem.note_id
        );
      } else if (props.context === "CONTACTS_NOTES") {
        index = props.data.findIndex(
          (x: any) => x.note_id === props.selectedRowItem.note_id
        );
      } else if (props.context === "CONTACTS_TIME_ALLOCATION") {
        index = props.data.findIndex(
          (x: any) =>
            x.timeallocation_id === props.selectedRowItem.timeallocation_id
        );
      } else if (props.context === "CONTACTS_CLIENT_ACTION") {
        index = props.data.findIndex(
          (x: any) => x.appointment_id === props.selectedRowItem.appointment_id
        );
      } else if (props.context === "CLIENT_ATTITUDE_TO_RISK") {
        index = props.data.findIndex(
          (x: any) =>
            x.attituderisk_id === props.selectedRowItem.attituderisk_id
        );
      } else if (props.context === "FEES_CHARGES") {
        index = props.data.findIndex(
          (x: any) => x.commission_id === props.selectedRowItem.commission_id
        );
      }
      if (props.context === "PARTNER_SERVICE_TYPE") {
        index = props.data.findIndex(
          (x: any) => x.servicetype_id === props.selectedRowItem.servicetype_id
        );
      } else if (props.context === "PARTNER_OBJECTIVES") {
        index = props.data.findIndex(
          (x: any) => x.objective_id === props.selectedRowItem.objective_id
        );
      } else if (props.context === "PARTNER_IDENTITY") {
        index = props.data.findIndex(
          (x: any) => x.identity_id === props.selectedRowItem.identity_id
        );
      } else if (props.context === "PARTNER_EMPLOYMENT") {
        index = props.data.findIndex(
          (x: any) => x.employment_id === props.selectedRowItem.employment_id
        );
      } else if (props.context === "PARTNER_DEPENDANT") {
        index = props.data.findIndex(
          (x: any) => x.dependant_id === props.selectedRowItem.dependant_id
        );
      } else if (props.context === "PARTNER_INCOME") {
        index = props.data.findIndex(
          (x: any) => x.income_id === props.selectedRowItem.income_id
        );
      } else if (props.context === "PARTNER_OUTGOINGS") {
        index = props.data.findIndex(
          (x: any) => x.expense_id === props.selectedRowItem.expense_id
        );
      } else if (props.context === "PARTNER_ATTITUDE_TO_RISK") {
        index = props.data.findIndex(
          (x: any) =>
            x.attituderisk_id === props.selectedRowItem.attituderisk_id
        );
      } else {
      }
      setRowSelection({ [index]: true });
      props.setCurrentRecord(index + 1);
    }
  }, [props.selectedRowItem]);

  useEffect(() => {
    if (props.isContactHistory) {
      if (
        props.selectedRowItem &&
        Object.keys(props.selectedRowItem).length !== 0 &&
        props.data.length > 0
      ) {
        let index = 0;
        if (props.context === "CONTACTS_NOTES") {
          index = props.data.findIndex((x: any) => x.note_id === props.tableId);
        }
        setRowSelection({ [index]: true });
        props.setCurrentRecord(index + 1);
      }
    }
  }, []);

  return (
    <div>
      {customColumns && customColumns.length > 0 && (
        <div>
          <MaterialReactTable
            columns={customColumns}
            data={props.data}
            enableRowSelection
            enableColumnActions={false}
            onRowSelectionChange={setRowSelection}
            enablePagination={false}
            // enableRowNumbers
            enableBottomToolbar={false}
            rowNumberMode="static"
            enableMultiRowSelection={false}
            muiTableBodyRowProps={({ row }) => ({
              onClick: row.getToggleSelectedHandler(),
              sx: { cursor: "pointer" },
            })}
            getRowId={(row) => row.id}
            enableRowActions={false}
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "Actions", //change header text
                // size: 300, //make actions column wider
                muiTableHeadCellProps: {
                  align: "center",
                },
                muiTableBodyCellProps: {
                  align: "center",
                },
              },
            }}
            renderRowActions={({ row, table }) => (
              <div className={styles.wrapper}>
                <Tooltip
                  content="View"
                  relationship="label"
                  positioning="below"
                >
                  <Button
                    appearance="subtle"
                    icon={
                      <Eye16Filled
                        primaryFill="var(--button-color)"
                        onClick={() => {
                          props.handleRowAction("view", row);
                        }}
                      />
                    }
                  ></Button>
                </Tooltip>
                <Tooltip
                  content="Edit"
                  relationship="label"
                  positioning="below"
                >
                  <Button
                    appearance="subtle"
                    icon={
                      <Edit16Filled
                        primaryFill="var(--button-color)"
                        onClick={() => {
                          props.handleRowAction("edit", row);
                        }}
                      />
                    }
                  ></Button>
                </Tooltip>
              </div>
            )}
            initialState={{
              columnPinning: { right: ["mrt-row-actions"] },
            }}
            positionActionsColumn="last"
            positionToolbarAlertBanner="bottom"
            state={{ rowSelection }}
          />
        </div>
      )}
    </div>
  );
};

export default TableDataList;
