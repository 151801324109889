import React, { useEffect, useMemo } from "react";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_RowSelectionState,
} from "material-react-table";
import {
  Eye16Filled,
  MoreHorizontal24Filled,
  Edit16Filled,
  Dismiss24Regular,
} from "@fluentui/react-icons";
import {
  Button,
  makeStyles,
  Tooltip,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
  Avatar,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Label,
  Dropdown,
  Option,
  useId,
  shorthands,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
} from "@fluentui/react-components";
import {
  checkUserPermission,
  formatedDate,
  getAssetCategoryByCaseType,
  getAssetTypeByCaseType,
  getClientType,
} from "../../../utils";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
import { UserPermissionsList } from "../../../config";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    cursor: "pointer",
  },
  contentHeader: {
    marginTop: "0",
  },
  scopeArea: {
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
  },
});

type ComponentProps = {
  style?: React.CSSProperties;
  [key: string]: any;
};

const LiabilityListItems = (props: ComponentProps) => {
  const styles = useStyles();
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>(
    {}
  );
  const columns = useMemo<MRT_ColumnDef<{ [key: string]: any }>[]>(
    () => [
      {
        accessorKey: "case_type",
        header: "Liability Category",
      },
      {
        accessorKey: "joint_indicator",
        header: "Owner",
        Cell: ({ renderedCellValue, row }) => (
          <>{getClientType(row.original.joint_indicator)}</>
        ),
      },
      {
        accessorKey: "borrower",
        header: "Borrower",
      },
      {
        accessorKey: "borrower_name1", //access nested data with dot notation
        header: "Borrower Name1",
      },
      {
        accessorKey: "borrower_name2",
        header: "Borrower Name2",
      },
      {
        accessorKey: "liability_type",
        header: "Type",
      },
      {
        accessorKey: "subtype",
        header: "Subtype",
      },
      {
        accessorKey: "repayment_method",
        header: "Repayment Method",
      },
      {
        accessorKey: "purpose",
        header: "Purpose",
      },
      {
        accessorKey: "start_date",
        header: "Start Date",
        Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
      },
      {
        accessorKey: "end_date",
        header: "End Date",
        Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,
      },
      {
        accessorKey: "term",
        header: "Term",
      },
      {
        accessorKey: "amount_borrowed",
        header: "Amount Borrowed",
      },
      {
        accessorKey: "amount_outstanding",
        header: "Amount Outstanding",
      },
      {
        accessorKey: "amount_outstanding_date",
        header: "Outstanding Date",
        Cell: ({ row }: any) => (
          <>{formatedDate(row.original.amount_outstanding_date)}</>
        ),
      },
      {
        accessorKey: "interest_rate",
        header: "Interest Rate",
      },
      {
        accessorKey: "interest_basis",
        header: "Interest Basis",
      },

      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "status_date",
        header: "Status Date",
        Cell: ({ row }: any) => <>{formatedDate(row.original.status_date)}</>,
      },
      {
        accessorKey: "note_text",
        header: "Admin Note",
        Cell: ({ row }: any) => (
          <>
            <ReactQuillComponent value={row.original.note_text} />
          </>
        ),
      },
      {
        accessorKey: "report_note",
        header: "Report Note",
        Cell: ({ row }: any) => (
          <>
            <ReactQuillComponent value={row.original.report_note} />
          </>
        ),
      },
      {
        accessorKey: "account_number",
        header: "Account Number",
      },
      {
        accessorKey: "mortgage_fixed_rate_end",
        header: "End of Fixed Rate",
        Cell: ({ row }: any) => (
          <>{formatedDate(row.original.mortgage_fixed_rate_end)}</>
        ),
      },
      {
        accessorKey: "property_value",
        header: "Property value",
      },
      {
        accessorKey: "property_address1",
        header: "Property Address",
        Cell: ({ row }: any) => (
          <p>
            {row.original.property_address1}
            <br />
            {row.original.property_address2}
            <br />
            {row.original.property_address3}
          </p>
        ),
      },
      {
        accessorKey: "loan_valuation_percentage",
        header: "Loan to valuation %",
      },

      {
        accessorKey: "provider",
        header: "Provider",
      },
      {
        accessorKey: "provider_contact_name",
        header: "Provider Contact",
      },
      {
        accessorKey: "provider_phone",
        header: "Provider Phone",
      },
      {
        accessorKey: "provider_address1",
        header: "Provider Address",
        Cell: ({ row }: any) => (
          <p>
            {row.original.provider_address1}
            <br />
            {row.original.provider_address2}
            <br />
            {row.original.provider_address3}
          </p>
        ),
      },
      {
        accessorKey: "property_town",
        header: "Property Town",
      },
      {
        accessorKey: "property_county",
        header: "Property County",
      },
      {
        accessorKey: "property_country",
        header: "Property Country",
      },
      {
        accessorKey: "provider_town",
        header: "Provider Town",
      },
      {
        accessorKey: "provider_county",
        header: "Provider Country",
      },
      {
        accessorKey: "provider_country",
        header: "Provider Country",
      },
      {
        accessorKey: "property_postcode",
        header: "Property Postcode",
      },
      {
        accessorKey: "provider_postcode",
        header: "Provider Postcode",
      },
      {
        accessorKey: "provider_note",
        header: "Provider Note",
        Cell: ({ row }: any) => (
          <>
            <ReactQuillComponent value={row.original.provider_note} />
          </>
        ),
      },
      {
        accessorKey: "covered",
        header: "Premium Covered",
        Cell: ({ row }: any) =>
          row.original.case_type !== "Mortgages" ? row.original.covered : "",
      },
      {
        accessorKey: "covered",
        header: "Covered by Insurance",
        Cell: ({ row }: any) =>
          row.original.case_type === "Mortgages" ? row.original.covered : "",
      },
    ],
    []
  );
  useEffect(() => {
    //do something when the row selection changes...

    if (
      Object.keys(rowSelection)[0] !== undefined &&
      props.handleRowSelection !== undefined &&
      props.data.length > 0
    ) {
      let selectedRow = props.data[Object.keys(rowSelection)[0]];

      props.handleRowSelection(selectedRow.liability_id);
    }
  }, [rowSelection]);
  useEffect(() => {
    if (
      props.activeItem &&
      Object.keys(props.activeItem).length !== 0 &&
      props.data.length > 0
    ) {
      let index = 0;
      index = props.data.findIndex(
        (x: any) => x.liability_id === props.activeItem.liability_id
      );

      setRowSelection({ [index]: true });
      props?.setCurrentRecord(index + 1);
    }
  }, [props.activeItem]);
  return (
    <div>
      <div>
        <MaterialReactTable
          columns={columns}
          data={props.data}
          enableRowSelection
          enableColumnActions={false}
          onRowSelectionChange={setRowSelection}
          enablePagination={false}
          enableRowNumbers
          enableBottomToolbar={false}
          rowNumberMode="static"
          enableMultiRowSelection={false}
          muiTableBodyRowProps={({ row }) => ({
            onClick: row.getToggleSelectedHandler(),
            sx: { cursor: "pointer" },
          })}
          getRowId={(row) => row.id}
          enableRowActions={false}
          displayColumnDefOptions={{
            "mrt-row-actions": {
              header: "Actions", //change header text
              // size: 300, //make actions column wider
              muiTableHeadCellProps: {
                align: "center",
              },
              muiTableBodyCellProps: {
                align: "center",
              },
            },
          }}

          renderRowActions={({ row, table }) => (
            <div className={styles.wrapper}>
               {checkUserPermission(
                loggedUser,
                UserPermissionsList.ALLOW_VIEW_CLIENT
              ) && (
              <Tooltip content="View" relationship="label" positioning="below">
                <Button
                  appearance="subtle"
                  icon={
                    <Eye16Filled
                      primaryFill="var(--button-color)"
                      onClick={() => {
                        props.handleRowAction("view", row);
                      }}
                    />
                  }
                ></Button>
              </Tooltip>
              )}
              <Tooltip content="Edit" relationship="label" positioning="below">
                <Button
                  appearance="subtle"
                  icon={
                    <Edit16Filled
                      primaryFill="var(--button-color)"
                      onClick={() => {
                        props.handleRowAction("edit", row);
                      }}
                    />
                  }
                ></Button>
              </Tooltip>
            </div>
          )}
          initialState={{
            columnPinning: { right: ["mrt-row-actions"] },
          }}
          positionActionsColumn="last"
          positionToolbarAlertBanner="bottom"
          state={{ rowSelection }}
        />
      </div>
    </div>
  );
};

export default LiabilityListItems;
