import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./style.css";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { setFormData, resetFormData } from "../../redux/reducer";
import { setDialogModalOptions } from "../../redux/modelDialogReducer";
import ASCButton from "../../components/Button";
import Search from "../../components/Search";
import { addBreadcrumbs } from "../../redux/appSettingsSlice";
import DropDownBox from "../../components/DropDownBox";
import {
  FullScreenMaximize16Regular,
  FullScreenMinimize16Regular,
  Dismiss16Regular,
} from "@fluentui/react-icons";
import ClientDataList from "./ClientDataList";
import { AddRegular, ChevronDownRegular } from "@fluentui/react-icons";

import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  Drawer,
  Menu,
  MenuTrigger,
  MenuPopover,
  MenuDivider,
  MenuItem,
  MenuList,
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
} from "@fluentui/react-components";
import {
  Button,
  Toolbar,
  ToolbarGroup,
  ToolbarButton,
  makeStyles,
} from "@fluentui/react-components";
import ClientActionPage from "./ClientActionPage";
import ModalDialog from "../../components/ModalDialog";
import { fetchSettings } from "../../redux/formSlice";
import { checkUserPermission } from "../../utils";
import { UserPermissionsList } from "../../config";
import OverlayLoader from "../../components/OverlayLoader";
import apiServices from "../../service";
import { ExtractMain } from "../utilities/ExtractLocate/extractMain";
import { setReloadClientList } from "../../redux/formUpdateReducer";

interface PropsType {
  setTabs?: Dispatch<SetStateAction<string>>;
  [x: string]: any;
}

const useStyles = makeStyles({
  toolbar: {
    justifyContent: "space-between",
  },
});

const scopeData: {
  value: any;
  label: string;
}[] = [
  {
    value: "client",
    label: "Client",
  },
  {
    value: "partner",
    label: "Partner",
  },
];

const List = ({ setTabs, module }: PropsType) => {
  const styles = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [page, setPage] = useState("listing");
  const [leftTabMenu, setLeftTabmenu] = useState("Client");
  const [clientFormSubmited, setClientFormSubmitted] = useState(false);
  const [drawerSize, setDrawerSize] = useState("small");
  const [activeClient, setActiveClient] = useState<{ [key: string]: any }>({});
  const [activeCustomerId, setActiveCustomerId] = useState<string | null>(null);
  const [activePartnerId, setActivePartnerId] = useState<string | null>(null);
  const [search, setSearch] = useState("");
  const [selectedScope, setSelectedScope] = useState([]);
  const [clientTypeList, setClientTypeList] = useState<any>([]);
  const [applyFilter, setApplyFilter] = useState(false);
  const [selectedClientType, setSelectedClientType] = useState("");
  const [selectedMenuItem, setSelectedMenuItem] = useState("Saved Searches");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState("");
  const [formDataUpdated, setFormDataUpdated] = useState(false);
  const [extract, setExtract] = useState<{ [key: string]: any }[]>([]);

  const [breadcrumbItem, setBreadcrumbItem] = useState([
    {
      key: 0,
      value: "Home",
      link: "/",
    },
    {
      key: 1,
      value: "Clients",
    },
  ]);
  const handleAddSearchClick = () => {
    setIsDialogOpen(true);
  };
  const handleMenuItemClick = (text: any) => {
    if (text == "") {
      setSelectedMenuItem("Saved Searches");
    } else {
      setSelectedMenuItem(text);
    }
  };

  const getExtracts = async () => {
    try {
      const response = await apiServices.extract.getExtract();
      if (!response.data.isError && response.data) {
        const modifiedData = [
          {
            SearchId: "",
            Title: "",
            Description: "",
            Condition: "",
            UserId: null,
          },
          ...response.data.data,
        ];
        setExtract(modifiedData);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const renderedMenuItems = extract.map((item: any, index: number) => (
    <MenuItem
      key={item.SearchId}
      onClick={() => {
        handleMenuItemClick(item.Title);
        setSelectedSearch(item.SearchId);
      }}
    >
      {item.Title}
    </MenuItem>
  ));

  const isFormDataUpdated: boolean = useSelector(
    (state: RootState) => state.someReducer?.isFormDataUpdated
  );
  const isFormupdated = useSelector(
    (state: RootState) => state.formUpdated?.isFormUpdated
  );
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const loggedUser = useSelector((state: RootState) => state.authUser);

  const formSettingsObj = useSelector((state: RootState) => state.formSettings);

  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    dispatch(
      addBreadcrumbs([
        {
          key: 0,
          value: "Home",
          link: "/",
        },
        {
          key: 1,
          value: "Clients",
        },
      ])
    );
  }, [dispatch]);

  useEffect(() => {
    if (setTabs) {
      setTabs("clients");
    }
    getExtracts();
    getClientTypeListOptions();
  }, []);

  const changePageMode = (mode: string) => {
    setPage(mode);
    setIsOpen(true);
  };
  const setActiveItem = (item: any) => {
    setActiveClient(item);
    setActiveCustomerId(item.customer_id);
    setActivePartnerId(item.NameAndAddress.associated_with);
  };
  const closePage = () => {
    setIsOpen(false);
    setActiveCustomerId(null);
    dispatch(setReloadClientList());
    setPage("listing");
  };
  const setFormUpdated = (val: boolean) => {
    setFormDataUpdated(val);
  };
  const getList = () => {
    setReloadList(true);
  };
  const setDialogModal = (options: any) => {
    setDialogModalOptions(options);
  };
  const resetClientFormSubmited = () => {
    setClientFormSubmitted(false);
  };
  const getClientTypeListOptions = async () => {
    const fieldLbl = "client_type:ModifiableList";
    try {
      const res = await apiServices.selectionList.getOptions(fieldLbl);
      if (res.data && res.data.data && res.data.data.length > 0) {
        const emptyOption = { value: "", label: "" };
        setClientTypeList([emptyOption, ...res.data.data[0].options]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const renderPage = (pageView: string) => {
    return (
      <ClientActionPage
        mode={pageView}
        changeMode={changePageMode}
        activeClient={activeClient}
        closePage={closePage}
        activeCustomerId={activeCustomerId}
        activePartnerId={activePartnerId}
        setActivePartnerId={setActivePartnerId}
        setActiveItem={setActiveItem}
        getList={getList}
        dialogModalOptions={dialogModalOptions}
        setDialogModal={setDialogModal}
        clientFormSubmited={clientFormSubmited}
        resetClientFormSubmited={resetClientFormSubmited}
        setBreadcrumbItem={setBreadcrumbItem}
        leftTabMenu={leftTabMenu}
        module={module}
      />
    );
  };
  const handleCloseAction = () => {
    if (isFormDataUpdated) {
      dispatch(
        setDialogModalOptions({
          open: true,
          content_line_1:
            "We have detected an attempt to move away from the current page",
          content_line_2: "This would cause all changes made to be lost",
          cancel: {
            onclick: () => {
              dispatch(setDialogModalOptions({ open: false }));
              setPage("listing");
              setActiveCustomerId(null);
              setIsOpen(false);
              setClientFormSubmitted(false);
              dispatch(resetFormData());
            },
            label: "Leave the Page",
          },
          no: {
            onclick: () => {
              dispatch(setDialogModalOptions({ open: false }));
            },
            label: "Stay on Page",
            type: "primary",
          },
        })
      );
    } else if (isFormupdated) {
      dispatch(
        setDialogModalOptions({
          open: true,
          content_line_1:
            "We have detected an attempt to move away from the current page",
          content_line_2: "This would cause all changes made to be lost",
          cancel: {
            onclick: () => {
              dispatch(setDialogModalOptions({ open: false }));
              setPage("listing");
              setActiveCustomerId(null);
              setIsOpen(false);
              setClientFormSubmitted(false);
              dispatch(resetFormData());
            },
            label: "Leave the Page",
          },
          no: {
            onclick: () => {
              dispatch(setDialogModalOptions({ open: false }));
            },
            label: "Stay on Page",
            type: "primary",
          },
        })
      );
    } else {
      setPage("listing");
      setActiveCustomerId(null);
      setIsOpen(false);
      dispatch(
        addBreadcrumbs([
          {
            key: 0,
            value: "Home",
            link: "/",
          },
          {
            key: 1,
            value: "Clients",
          },
        ])
      );
    }
  };
  useEffect(() => {
    if (
      formSettingsObj &&
      formSettingsObj.data &&
      Object.keys(formSettingsObj.data).length === 0
    ) {
      dispatch(fetchSettings());
    }
  }, [formSettingsObj]);
  const handleSearchId = (id: any) => {
    setSelectedSearch(id);
    setApplyFilter(true);
  };
  return (
    <>
      {/* page top header section */}
      <OverlayLoader isLoading={isLoading} />
      <div className="page-top-area">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div>
            {checkUserPermission(
              loggedUser,
              UserPermissionsList.ALLOW_ADD_CLIENT
            ) && (
              <ASCButton
                shape={"rounded"}
                label={"Add Client"}
                className="asc-button-primary"
                appearance="primary"
                onItemClick={() => {
                  if (Object.keys(formSettingsObj.data).length !== 0) {
                    setActiveCustomerId(null);
                    changePageMode("add");
                  }

                  setIsOpen(true);
                }}
                disabled={isOpen ? true : false}
                size="medium"
              />
            )}
          </div>
        </div>
      </div>
      {/* page content area */}
      <div className="page-content-area">
        {checkUserPermission(
          loggedUser,
          UserPermissionsList.ALLOW_VIEW_CLIENT
        ) && (
          <>
            {page && page === "listing" && (
              <div className="global-search-filter">
                <Search
                  setSearch={setSearch}
                  search={search}
                  isLoading={isLoading}
                />

                <DropDownBox
                  placeholder="Scope"
                  multiselect={true}
                  values={scopeData}
                  onOptionSelect={(event: any, option: string | any) => {
                    setSelectedScope(option.selectedOptions);
                  }}
                />
                <DropDownBox
                  placeholder="Client Type"
                  values={clientTypeList}
                  onOptionSelect={(event: any, option: string | any) => {
                    setSelectedClientType(option.optionValue);
                  }}
                />
                {/* <DropDownBox
                  placeholder="Recent Search"
                  values={scopeData}
                  disabled={true}
                /> */}
                <Menu>
                  <MenuTrigger disableButtonEnhancement>
                    <Button
                      style={{
                        fontWeight: "normal",
                        color: "#606060",
                        border: "1px solid grey",
                      }}
                      icon={<ChevronDownRegular />}
                      iconPosition="after"
                    >
                      {selectedMenuItem}
                    </Button>
                  </MenuTrigger>
                  <MenuPopover>
                    <MenuList>
                      <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                        {renderedMenuItems}
                      </div>
                      <MenuDivider />
                      <MenuItem
                        icon={<AddRegular />}
                        onClick={handleAddSearchClick}
                      >
                        Add Search
                      </MenuItem>

                      <MenuItem onClick={handleAddSearchClick}>
                        View More
                      </MenuItem>
                    </MenuList>
                  </MenuPopover>
                </Menu>
                <ASCButton
                  shape={"rounded"}
                  label={"Apply Filter"}
                  className="asc-button-primary"
                  appearance="primary"
                  onItemClick={() => {
                    setApplyFilter(true);
                  }}
                  size="medium"
                />
              </div>
            )}
            <ClientDataList
              page={page}
              changeMode={changePageMode}
              setActiveItem={setActiveItem}
              search={search}
              reloadList={reloadList}
              setReloadList={setReloadList}
              dialogModalOptions={dialogModalOptions}
              setDialogModal={setDialogModal}
              setIsLoading={setIsLoading}
              selectedScope={selectedScope}
              applyFilter={applyFilter}
              setApplyFilter={setApplyFilter}
              selectedClientType={selectedClientType}
              searchId={selectedSearch}
            />
          </>
        )}
        <Drawer
          modalType="non-modal"
          type="overlay"
          separator
          open={isOpen}
          size={drawerSize === "small" ? "large" : "full"}
          position="end"
          style={{
            width: "calc(100vw)",
            height: drawerSize === "small" ? "calc(100vh - 182px)" : "100vh",
            margin: 0,
            padding: 4,
          }}
          onOpenChange={(_, { open }) => setIsOpen(open)}
          className={drawerSize === "small" ? "common-drawer" : ""}
        >
          <DrawerHeader className="drawerHead">
            <DrawerHeaderTitle
              action={
                <Toolbar className={styles.toolbar}>
                  <ToolbarGroup>
                    {drawerSize === "large" && (
                      <ToolbarButton
                        aria-label="Close panel"
                        appearance="subtle"
                        icon={<FullScreenMinimize16Regular />}
                        onClick={() => {
                          setDrawerSize("small");
                        }}
                      />
                    )}
                    {drawerSize === "small" && (
                      <ToolbarButton
                        aria-label="Close panel"
                        appearance="subtle"
                        icon={<FullScreenMaximize16Regular />}
                        onClick={() => {
                          setDrawerSize("large");
                        }}
                      />
                    )}

                    <ToolbarButton
                      aria-label="Close panel"
                      appearance="subtle"
                      icon={<Dismiss16Regular />}
                      onClick={handleCloseAction}
                    />
                  </ToolbarGroup>
                </Toolbar>
              }
            >
              {page && page === "add"
                ? "Add Client"
                : activeClient?.NameAndAddress?.first_names !== "" &&
                  activeClient?.NameAndAddress?.first_names
                ? activeClient?.NameAndAddress?.last_name +
                  ", " +
                  activeClient?.NameAndAddress?.first_names
                : activeClient?.NameAndAddress?.last_name
                ? activeClient?.NameAndAddress?.last_name
                : ""}
            </DrawerHeaderTitle>
          </DrawerHeader>

          <DrawerBody className="drawerBody">{renderPage(page)}</DrawerBody>
        </Drawer>
        <ModalDialog options={dialogModalOptions} />
      </div>
      <Dialog
        modalType="non-modal"
        open={isDialogOpen}
        onOpenChange={(_, data) => setIsDialogOpen(data.open)}
      >
        <DialogSurface style={{ maxWidth: "75vw" }}>
          <DialogBody>
            <DialogTitle>Saved Searches</DialogTitle>
            <DialogContent>
              <ExtractMain
                setIsDialogOpen={setIsDialogOpen}
                searchId={handleSearchId}
              />
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  );
};

export default List;
