import { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./style.css";
import {
  Button,
  Input,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
  useId,
  ToastBody,
  Caption1Strong,
  Persona,
  Avatar,
} from "@fluentui/react-components";
import { ArrowLeft24Filled } from "@fluentui/react-icons";
import ASCButton from "../../components/Button";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import apiServices from "../../service";
import { useFormik } from "formik";
import * as Yup from "yup";
import { convertStringToArray, getUserAvatharName } from "../../utils";
import { getUserProfileInfo } from "../../redux/authSlice";
import { NavDrawer, NavDrawerBody, NavItem } from "@fluentui/react-nav-preview";
import { Outlet, useNavigate } from "react-router-dom";

const goBack = () => {
  window.history.go(-1);
};

const ProfileSettings = (props: any) => {
  const toasterId = useId("toaster");
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = useSelector((state: RootState) => state.authUser);

  const [isOpen, setIsOpen] = useState(true);
  useEffect(() => {
    if (authUser) {
      getDetail(authUser?.userId);
    }
  }, []);

  const [formField, setFormField] = useState({
    full_name: "",
    short_name: "",
    email: "",
    prefix: "",
    domain: "",
  });

  const formik = useFormik({
    initialValues: {
      full_name: formField?.full_name ? formField.full_name : "",
      short_name: formField?.short_name ? formField.short_name : "",

      emailPrefix: formField?.prefix ? formField.prefix : "",
    },
    validationSchema: Yup.object({
      full_name: Yup.string()
        .required("Name is required")
        .max(20, "Full Name cannot be longer than 20 characters"),
      short_name: Yup.string()
        .required("Short name is required")
        .max(20, "Short Name cannot be longer than 20 characters"),
      // emailPrefix: Yup.string().required("Email is required"),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      // Handle form submission logic
      // console.log(values);
      updateProfile(values);
    },
  });

  const getDomain = (inputEmail: string) => {
    const atIndex = inputEmail.indexOf("@");
    if (atIndex !== -1) {
      const prefix = inputEmail.substring(0, atIndex);
      const domain = inputEmail.substring(atIndex + 1);

      return { prefix: prefix, domain: domain };
    } else {
      return { prefix: "", domain: "" };
    }
  };
  const getDetail = (userId: any) => {
    apiServices.userProfile
      .getDetail(userId)
      .then((response) => {
        if (response.data.data) {
          setFormField((prev) => ({
            ...prev,
            full_name: response.data.data.full_name,
            short_name: response.data.data.short_name,
            email: response.data.data.user_email,
            prefix: response.data.data.user_email
              ? getDomain(response.data.data.user_email).prefix
              : "",
            domain: response.data.data.user_email
              ? getDomain(response.data.data.user_email).domain
              : "",
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateProfile = (values: any) => {
    const { full_name, short_name, emailPrefix } = values;
    let reqBody = {
      user_email: emailPrefix + "@" + formField.domain,
      full_name: full_name,
      short_name: short_name,
    };
    if (authUser?.userId && authUser?.userId !== "") {
      apiServices.userProfile
        .updateDetail(authUser?.userId, reqBody)
        .then((response) => {
          if (response.data.isError === false) {
            getDetail(authUser?.userId);
            props.notify(response.data.message, "", "success");
            props.setMode("view");
            dispatch(getUserProfileInfo(authUser?.userId));
          } else {
            props.notify("Something went wrong", "", "error");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <Toaster toasterId={toasterId} />
      <div className="profile_settings">
        <div className="profile_card_bg">
          <Avatar
            name={formField?.full_name}
            size={128}
            color="navy"
            className="avatar"
          />
        </div>
        <div className="profile_settings_body">
          <div>
            <Button
              onClick={goBack}
              appearance="transparent"
              icon={<ArrowLeft24Filled />}
              className="back_button"
            >
              Back
            </Button>
            <NavDrawer type="inline" size="medium" open={isOpen}>
              {!convertStringToArray(
                process.env.REACT_APP_HIDE_SIDE_TAB
              ).includes("profile_account_btn") ? (
                <NavItem
                  value="account"
                  onClick={() => {
                    navigate("/profile/account");
                  }}
                >
                  Account
                </NavItem>
              ) : (
                ""
              )}
              {!convertStringToArray(
                process.env.REACT_APP_HIDE_SIDE_TAB
              ).includes("profile_email_btn") ? (
                <NavItem
                  onClick={() => {
                    navigate("/profile/emailSettings");
                  }}
                  value="detail"
                >
                  Email Settings
                </NavItem>
              ) : (
                ""
              )}
                            {!convertStringToArray(
                process.env.REACT_APP_HIDE_SIDE_TAB
              ).includes("profile_password_btn") ? (
              <NavItem value="password">Change Password</NavItem>
            ) : (
              ""
            )}
            </NavDrawer>
          </div>
          <div className="outlet_body">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileSettings;
