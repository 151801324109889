import React, { useEffect, useState } from "react";
import LogoNew from "../../assets/logo/assystCloudLogo.png";
import "./style.css";
import {
  Input,
  Button,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
  useId,
  ToastBody,
  Field,
} from "@fluentui/react-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import apiServices from "../../service";
import { ApiConstants, apiUrls } from "../../config";
import { useLocation } from "react-router-dom";
function LoginPage() {
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const [emailType, setEmailType] = useState("private");
  const location = useLocation();
  let EmailDomains = ["gmail.com", "yahoo.com"];
  const validateSchema = Yup.object().shape({
    org: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("Please enter a valid email")
      .matches(
        new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"),
        "Please enter valid email"
      )
      .required("This field is required"),
  });
  const formik = useFormik({
    initialValues: {
      org: "",
      email: "",
    },
    validationSchema: validateSchema,
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values);
    },
  });
  useEffect(() => {
    if(location && location.state && location.state !== undefined && location.state.isOrganizationRequired ){
      setEmailType("public");
    }else{
      if (EmailDomains.includes(formik.values.email.split("@")[1])) {
        setEmailType("public");
      } else {
        setEmailType("private");
      }
    }
  }, [formik.values.email]);
  useEffect(()=>{
    if(location.state && location.state !== undefined && location.state.isOrganizationRequired ){
      setEmailType("public");
      if (location.state.userEmail && formik) {
        formik.setValues((prevValues) => ({
          ...prevValues,
          email: location.state.userEmail,
        }));
      }
    }

  },[location]);
  const notify = (title?: string, message?: string, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle>{title ?? title}</ToastTitle>
        <ToastBody>{message ?? message}</ToastBody>
      </Toast>,
      {
        intent: type ? type : "success",
        position: "top-end",
        timeout: type && type === "error" ? 20000 : 4000,
      }
    );
  const handleSubmit = (values: any) => {
    const { email, organization } = values;
    const reqBody = {
      email: email,
      org: organization,
    };

    apiServices.auth
      .login(reqBody)
      .then((response) => {
        if (!response.data.isError) {
          // If no error and URL is provided, redirect
          if (response.data.data && response.data.data.url) {
            window.location.href = response.data.data.url;
          } else {
            // If no URL is provided, handle it as needed
            notify(
              "Login successful but no redirect URL provided.",
              "",
              "info"
            );
          }
        } else {
          // If there is an error in the response
          if (
            response.data.error &&
            Object.keys(response.data.error).length !== 0
          ) {
            notify(response.data.error.description, "", "error");

          } else {
            notify("Invalid login credentials. Please try again.", "", "error");
          }
        }
      })
      .catch((err) => {
        console.log(err);
        notify(
          "An error occurred during login. Please try again.",
          "",
          "error"
        );
      });
  };

  return (
    <div className="login-main-body">
      <Toaster toasterId={toasterId} />
      <form
        action={`${
          process.env.REACT_APP_PUBLIC_URL + ApiConstants.auth.login2
        }`}
        method="post"
      >
        <div className="login-form-body">
          <div className="login-avatar-wrapper">
            <img src={LogoNew} alt="logo" width={300} height={100} />
          </div>
          <div className="login">Login</div>
          <Field
            validationState={formik.errors.email ? "error" : "none"}
            validationMessage={`${
              formik.errors.email !== undefined ? formik.errors.email : ""
            }`}
          >
            <Input
              placeholder="Email"
              size="large"
              onChange={formik.handleChange}
              name="email"
              value={formik.values.email}
              autoComplete="off"
            />
          </Field>

          {emailType === "public" && (
            <Field
              validationState={formik.errors.org ? "error" : "none"}
              validationMessage={formik.errors.org ? formik.errors.org : ""}
            >
              <Input
                size="large"
                placeholder="Organisation"
                onChange={formik.handleChange}
                value={formik.values.org}
                name="org"
              />
            </Field>
          )}

          <Button
            appearance="primary"
            type="submit"
            disabled={
              emailType === "public"
                ? formik.values.email && formik.values.org !== ""
                  ? false
                  : true
                : formik.values.email !== ""
                ? false
                : true
            }
            className={formik.values.email !== "" ? "asc-button-primary" : ""}
          >
            Submit
          </Button>
        </div>
      </form>
      <div className="login-footer-message">
        <p className="footer_color">
          <span className="red-text">Practice Management Software </span>for
          Independent Financial Advisers
        </p>
        <p className="footer_link">
          By logging into Cloud Assyst, you consent to our{" "}
          <a href="https://www.assyst-software.com/" target="blank">
            terms of service
          </a>
          .
        </p>
      </div>
    </div>
  );
}

export default LoginPage;
