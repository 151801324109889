import React, { useEffect, useState } from "react";
import "../style.css";
import { Tab, TabList } from "@fluentui/react-components";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { configFm } from "../../../types";
import apiServices from "../../../service";
import ManageUserFormContainer from "./ManageUserFormContainer";
import FormContainer from "../FormContainer";
import OverlayLoader from "../../../components/OverlayLoader";
import { convertStringToArray } from "../../../utils";
type ComponentProps = {
  [x: string]: any;
  changeMode?: (mode: string) => void;
  setActiveItem?: (item: any) => void;
  closePage?: () => void;
};
const UserManagementForm = (props: ComponentProps) => {
  const [formContext, setFormContext] = useState("SYSTEM_MANAGER_USERLIST");
  const [formContexts, setFormContexts] = useState(
    "SYSTEM_MANAGER_COMMUNICATION_CREDENTIALS"
  );
  const [initialData, setInitialData] = useState({});
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const [configForm, setConfigForm] = useState<configFm>();
  const [listDataItems, setListDataItems] = useState<any>([]);
  const [permissionsList, setPermissionsList] = useState<any>([]);
  const [compositeRoles, setCompositeRoles] = useState<any>([]);
  const [activeTab, setActiveTab] = useState("tab1");
  const [mode, setMode] = useState("edit");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState<{
    [key: string]: any;
  }>({});
  // useEffect(() => {
  //   if (
  //     formContext &&
  //     formSettingsObj?.systemAdmin &&
  //     formSettingsObj?.systemAdmin.formConfig !== undefined
  //   ) {
  //     let contextToMatch = "";

  //     if (activeTab === "tab1") {
  //       contextToMatch = "SYSTEM_MANAGER_COMPANY_DETAILS";
  //     } else if (activeTab === "tab2") {
  //       contextToMatch = "SYSTEM_MANAGER_COMMUNICATION_CREDENTIALS";
  //     }

  //     if (contextToMatch) {
  //       let configObj = formSettingsObj.systemAdmin.formConfig.find(
  //         (item: any) => item.context === contextToMatch
  //       );

  //       if (configObj) {
  //         setConfigForm(configObj);
  //       }
  //     }
  //   }
  // }, [formSettingsObj, formContext, activeTab]);

  useEffect(() => {
    if (
      formContext &&
      formSettingsObj?.systemAdmin &&
      formSettingsObj?.systemAdmin.formConfig !== undefined
    ) {
      let contextToMatch = "";
      if (activeTab === "tab1") {
        contextToMatch = "SYSTEM_MANAGER_USERLIST";
      } else if (activeTab === "tab2") {
        contextToMatch = "SYSTEM_MANAGER_COMMUNICATION_CREDENTIALS";
      }
      let configObj = formSettingsObj.systemAdmin.formConfig.find(
        (item: any) => item.context === contextToMatch
      );
      if (configObj) {
        setConfigForm(configObj);
      }
    }
  }, [formSettingsObj, activeTab]);

  useEffect(() => {
    getDataList();
    getUserPermissions();
  }, []);
  const handleTabChange = (event: any, data: any) => {
    setActiveTab(data.value);
  };
  const loadListData = () => {
    getDataList();
  };
  const getUserPermissions = async () => {
    setIsLoading(true);
    try {
      const response =
        await apiServices.systemManager.manageUsers.getPermissions();
      if (response && response.data && response.data.data.length > 0) {
        const compositeRoles = response.data.data.filter(
          (role: any) => role.composite === true
        );
        setCompositeRoles(compositeRoles);
        setPermissionsList(response.data.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const getDataList = async () => {
    setIsLoading(true);
    try {
      const response = await apiServices.systemManager.manageUsers.getList();

      if (response && response.data && response.data.data.length > 0) {
        const newArr = response.data.data.map((v: any) => ({
          ...v,
          id: v.user_id,
        }));
        setListDataItems(newArr);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <div className="admin-form-container" data-testid="ManageUserFormContainer">
      <OverlayLoader isLoading={isLoading} />        
        <ManageUserFormContainer
          context={formContext}
          config={configForm}
          notify={props?.notify}
          isFormAdmin={true}
          dataList={listDataItems}
          reloadData={loadListData}
          permissionsList={permissionsList}
          compositeRoles={compositeRoles}
          setSelectedUserData={setSelectedUserData}
        />
    
    </div>
  );
};

export default UserManagementForm;
